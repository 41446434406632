
// This service lives on rootscope and doesn't need to be injected to controllers. just use:
//$scope.publish('eventName', data);

//$scope.subscribe('eventName', function (data) {
//    //do somthing
//});

angular.module("DigiLean").service('PubSubService', function () {
    return { Initialize: Initialize };

    function Initialize(scope) {


        // New 
        // var channels = {};
        // scope.constructor.prototype.publish = scope.constructor.prototype.publish
        //     || function (topic) {
        //         var args = Array.prototype.slice.call(arguments, 1);
        //         // if (topic && topic != "ProjectAttributeUpdated") {
        //         //     topic = "ProjectAttributeUpdated";
        //         // }
        //         console.debug("publish: " + topic);
        //         if (!channels[topic]) {
        //             return;
        //         }
        //         console.debug("publish to handler: " + topic + "to " + channels[topic].length + " handlers");
        //         var callbacks = channels[topic];
        //         callbacks.forEach(function (callback) {
        //             console.debug("publish topic: " + topic + " to  handler");   
        //             try {
        //                 callback.apply(null, args);
        //             } 
        //             catch(err) {
        //                 console.debug("failed to apply event to handler:" + callback);
        //             }

        //         });
        //     }

        // scope.constructor.prototype.subscribe = scope.constructor.prototype.subscribe
        //     || function (topic, callback) {
        //         if (!(callback instanceof Function)) {
        //             throw new Error('callback must be a function');
        //         }
        //         console.debug("subscribe: " + topic);
        //         if (!channels[topic]) {
        //             channels[topic] = [];
        //         }
        //         channels[topic].push(callback);
        //     }

        //     scope.constructor.prototype.unsubscribe = scope.constructor.prototype.unsubscribe
        //     || function (topic, callback) {
        //         if (!channels[topic]) {
        //             return;
        //           }

        //           channels[topic].forEach(function(value, index) {
        //             if (value === callback) {
        //               channels[topic].splice(index, 1);
        //             }
        //           });
        //     }
        //ORIG - 
        //Keep a dictionary to store the events and its subscriptions
        var publishEventMap = {};
        // Register publish events
        scope.constructor.prototype.publish = scope.constructor.prototype.publish
            || function () {
                var _thisScope = this,
                    handlers,
                    args,
                    evnt;
                //Get event and rest of the data
                args = [].slice.call(arguments);
                evnt = args.splice(0, 1);

                var handlers = publishEventMap[evnt] || [];
                //console.log("publish to handler: " + evnt + "to " + handlers.length + " handlers");
                //Loop though each handlerMap and invoke the handler
                handlers.forEach(function (handlerMap) {

                    //console.debug("publish: " + evnt + " to handler");
                    try {
                        handlerMap.handler.apply(_thisScope, args);
                    }
                    catch (err) {
                        (console.error || console.log).call(console, "Failed to publish event to handler: " + handlerMap.handler + (err.stack || err));
                    }
                });
            }

        //Register Subscribe events
        scope.constructor.prototype.subscribe = scope.constructor.prototype.subscribe
            || function (evnt, handler) {
                var _thisScope = this,
                    handlers = (publishEventMap[evnt] = publishEventMap[evnt] || []);
                
                //    console.debug("subscribe: " + evnt);
                //Just keep the scopeid for reference later for cleanup
                handlers.push({ $id: _thisScope.$id, handler: handler });
                //When scope is destroy remove the handlers that it has subscribed.  
                _thisScope.$on('$destroy', function () {
                    for (var i = 0, l = handlers.length; i < l; i++) {
                        if (handlers[i].$id === _thisScope.$id) {
                            handlers.splice(i, 1);
                            // DEBUG
                            //console.log("destroy: " + handler[i]);
                            break;
                        }
                    }
                });
            }

    }
}).run(['$rootScope', 'PubSubService',function ($rootScope, PubSubService) {
    PubSubService.Initialize($rootScope);
}]);